import { Flex, Box, Link } from '@chakra-ui/react';
import { Logo } from '../Logo';
import React from 'react';

export const MarketingNav = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mx="auto"
      width="full"
      maxW="1200px"
      sx={{
        '> a:first-of-type': {
          textDecoration: 'none',
        },
      }}
    >
      <Link href="/">
        <Flex
          flex={['1 1 auto', '1 1 auto', 'none']}
          alignItems="center"
          gap="3"
        >
          <Logo width={34} />
          <Box fontWeight="semibold" color="gray.50" as="span" fontSize="35px">
            Magnet
          </Box>
        </Flex>
      </Link>
      {children}
    </Flex>
  );
};
