export const Logo = ({
  width = 32,
  bottomFill = 'white',
  isMono = false,
}: {
  width?: number;
  bottomFill?: string;
  isMono?: boolean;
}) => (
  <svg
    width={width}
    height={(width * 28) / 32}
    viewBox="0 0 32 28"
    fill="none"
    className="flex-none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4473 1.24461C16.4473 0.692324 16.8964 0.241057 17.4475 0.278271C25.0587 0.79229 31.0732 7.12905 31.0732 14.8706L31.0732 22.7779C31.0732 23.1893 30.7398 23.5228 30.3284 23.5228L22.4382 23.5228C22.0268 23.5228 21.6933 23.1893 21.6933 22.7779L21.6933 14.8706C21.6933 12.3132 19.8635 10.1833 17.4416 9.71864C16.8992 9.61458 16.4473 9.17681 16.4473 8.62453L16.4473 1.24461Z"
      fill={isMono ? 'currentColor' : '#F5385A'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6553 1.24461C14.6553 0.692324 14.2061 0.241057 13.6551 0.278271C6.04388 0.79229 0.0292972 7.12905 0.0292975 14.8706L0.029298 22.7779C0.029298 23.1893 0.362773 23.5228 0.774134 23.5228L8.66438 23.5228C9.07574 23.5228 9.40922 23.1893 9.40922 22.7779L9.40922 14.8706C9.40922 12.3132 11.2391 10.1833 13.6609 9.71864C14.2033 9.61458 14.6553 9.17681 14.6553 8.62453L14.6553 1.24461Z"
      fill={isMono ? 'currentColor' : '#308DE2'}
    />
    <path
      d="M8.58982 25.436L0.848692 25.436C0.396152 25.436 0.0292969 25.8029 0.0292969 26.2554C0.0292969 26.708 0.396153 27.0748 0.848692 27.0748L8.58982 27.0748C9.04236 27.0748 9.40922 26.708 9.40922 26.2554C9.40922 25.8029 9.04236 25.436 8.58982 25.436Z"
      fill={isMono ? 'currentColor' : bottomFill}
    />
    <path
      d="M30.2573 25.436L22.5162 25.436C22.0636 25.436 21.6968 25.8029 21.6968 26.2554C21.6968 26.708 22.0636 27.0748 22.5162 27.0748L30.2573 27.0748C30.7098 27.0748 31.0767 26.708 31.0767 26.2554C31.0767 25.8029 30.7098 25.436 30.2573 25.436Z"
      fill={isMono ? 'currentColor' : bottomFill}
    />
  </svg>
);
