import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Grid,
  Image,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import {
  CheckCircle,
  CircleDot,
  FileCode2,
  FileText,
  MessageSquare,
  XCircle,
} from 'lucide-react';
import { getAuth, buildClerkProps } from '@clerk/nextjs/server';
import { GetServerSideProps } from 'next';
import { MarketingNav } from '../components/marketing/MarketingNav';

import localFont from 'next/font/local';
import { Logo } from '../components/Logo';

const degular = localFont({
  src: [
    {
      path: './fonts/Degular-Regular.woff2',
      weight: '400',
    },
    {
      path: './fonts/Degular-Medium.woff2',
      weight: '500',
    },
    {
      path: './fonts/Degular-Semibold.woff2',
      weight: '600',
    },
  ],
  preload: true,
  variable: '--font-degular',
});

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { userId } = getAuth(ctx.req);
  if (userId) {
    return {
      redirect: {
        destination: '/code',
        permanent: false,
      },
    };
  }
  return { props: { ...buildClerkProps(ctx.req) } };
};

const Home = () => {
  return (
    <>
      <Flex
        color="gray.200"
        className={degular.className}
        direction="column"
        minHeight="100vh"
        bg="gray.900"
        p={['16px', '32px', '64px', '80px']}
        pb={0}
      >
        <Flex direction="column" gap="24">
          <MarketingNav>
            <Button
              as="a"
              px="5"
              flex="none"
              bgColor="brandBlue.500"
              color="gray.900"
              _hover={{ bgColor: 'blue.500' }}
              borderRadius="lg"
              href="/code"
              fontSize="xl"
              fontWeight="semibold"
            >
              Get started
            </Button>
          </MarketingNav>
          <Flex
            flexDirection="column"
            alignItems="center"
            mx="auto"
            width="full"
            maxW="1200px"
          >
            <Flex
              justifyContent="center"
              my={[0, 8, 24, 24]}
              transform={['scale(0.5)', 'scale(0.6)', 'scale(0.8)', 'scale(1)']}
              transitionProperty="transform margin"
              transitionDuration="0.2s"
              w="full"
              position="relative"
            >
              <Image
                position="absolute"
                zIndex={1}
                top="-84px"
                w={809}
                h={234}
                pointerEvents="none"
                src="/marketing/hero/1.svg"
                alt="1"
                opacity={0}
                maxW="none"
                animation="hero1 0.3s ease-in 0.2s forwards"
              />
              <Image
                position="absolute"
                zIndex={2}
                top="-127px"
                w={247}
                h={160}
                pointerEvents="none"
                src="/marketing/hero/2.svg"
                alt="2"
                maxW="none"
                opacity={0}
                animation="hero2 0.3s ease-in 0.5s forwards"
              />
              <Image
                position="absolute"
                zIndex={3}
                bottom="-38px"
                w={63}
                h={63}
                pointerEvents="none"
                src="/marketing/hero/3.svg"
                alt="3"
                opacity={0}
                animation="hero3 0.2s ease-in 0.8s forwards"
              />
              <Image
                position="absolute"
                zIndex={4}
                bottom="-68px"
                w={822}
                h={232}
                pointerEvents="none"
                src="/marketing/hero/4.svg"
                alt="4"
                maxW="none"
                opacity={0}
                animation="hero4 0.6s ease-in 1.2s forwards"
              />
              <Button
                as="a"
                href="/code"
                bg="brandRed.500"
                _hover={{
                  bg: 'brandRed.600',
                  transform: 'scale(1.015)',
                }}
                color="white"
                fontWeight={500}
                fontSize="6xl"
                px={10}
                py={6}
                height="auto"
                flex="none"
                fontFamily="ui-monospace, Menlo, Monaco, monospace"
                borderRadius="3xl"
              >
                Fix issue
              </Button>
            </Flex>
            <Text
              as="h1"
              textAlign="center"
              my={[12, 20]}
              lineHeight={1.15}
              fontWeight="semibold"
              fontSize={['5xl', '6xl', '7xl']}
            >
              AI coding help for&nbsp;product&nbsp;teams
            </Text>
            <Box
              overflow="hidden"
              aspectRatio="1.55"
              width="full"
              position="relative"
              borderRadius="14px"
            >
              <Box as="video" objectFit="cover" autoPlay muted loop playsInline>
                <source src="magnet.mp4" type="video/mp4" />
              </Box>
            </Box>
            <Flex
              flexDir={['column', 'column', 'column', 'row']}
              gap={12}
              pt={[8, 16]}
            >
              <Flex gap={[4, 12]}>
                <Tab
                  color="pink.300"
                  title="Share AI threads"
                  description="Create multiple concurrent threads all teammates can interact with"
                >
                  <MessageSquare size={28} />
                </Tab>
                <Tab
                  color="blue.300"
                  title="Select files"
                  description="Select multiple code or file directories from your local machine"
                >
                  <FileCode2 size={28} />
                </Tab>
              </Flex>
              <Flex gap={12}>
                <Tab
                  color="purple.300"
                  title="Fix issues"
                  description="Connect Linear or GitHub issues to reference them as context for AI threads"
                >
                  <CircleDot size={28} />
                </Tab>
                <Tab
                  color="green.300"
                  title="Reference docs"
                  description="Connect Notion specs to reference them in AI threads"
                >
                  <FileText size={28} />
                </Tab>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Section my={48}>
          <SectionHeading
            title={
              <>
                Like ChatGPT, without all the <Key>⌘C</Key> and <Key>⌘V</Key>
              </>
            }
            description="Organize projects for quick, contextual AI threads"
          />
          <Flex flexDir={['column', 'column', 'column', 'row']} gap={12}>
            <Feature
              title="Auto-suggested files"
              description="Code files are automatically included in your question context to personalize and improve accuracy of AI recommendations."
              imageUrl="/marketing/auto.png"
            />
            <Feature
              title="Full coding context"
              description="Ask questions about multiple files across multiple repos. Reference Linear, GitHub and Notion issues with a single click."
              imageUrl="/marketing/context.png"
            />
          </Flex>
        </Section>
        <Section>
          <SectionHeading
            title="Get inspired"
            description="Prompt templates to help you get started"
          />
          <Grid
            gap={[4, 8]}
            templateColumns={[
              'repeat(2, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
              'repeat(4, 1fr)',
            ]}
            flexWrap="wrap"
          >
            <PromptTemplate>
              Suggest a quick fix for a bug or enhancement
            </PromptTemplate>
            <PromptTemplate>
              Propose an implementation for a product spec
            </PromptTemplate>
            <PromptTemplate>
              Find areas for improvement in my codebase
            </PromptTemplate>
            <PromptTemplate>
              Improve error handling in a specific file
            </PromptTemplate>
            <PromptTemplate>
              Debug an error message and propose a&nbsp;fix
            </PromptTemplate>
            <PromptTemplate>
              Explain a file or document concisely
            </PromptTemplate>
          </Grid>
        </Section>
        <Section>
          <SectionHeading
            title="Use your key or ours"
            description="Simple pricing for teams of all sizes"
          />
          <Flex gap="32px" flexDir={['column', 'column', 'column', 'row']}>
            <Flex
              flex="1"
              flexDir="column"
              p={[8, 12, 16]}
              borderRadius="lg"
              overflow="hidden"
              position="relative"
            >
              <Box
                position="absolute"
                bg="brandRed.500"
                opacity={0.1}
                inset="0"
              />
              <Box
                fontSize={['2xl', '3xl', '4xl']}
                fontWeight="medium"
                lineHeight={1.2}
                mb="8"
              >
                Use your own OpenAI API key for free
              </Box>
              <Flex flex="1" flexDir="column" gap="3" fontSize="18px">
                <Bullet label="Unlimited GPT-4 assistant usage" />
                <Bullet
                  icon="/integrations/linear.png"
                  label="Linear issues integration"
                />
                <Bullet
                  icon="/integrations/github.png"
                  label="GitHub issues integration"
                />
                <Bullet
                  icon="/integrations/notion.png"
                  label="Notion docs integration"
                />
              </Flex>
            </Flex>
            <Box
              flex="1"
              flexDir="column"
              p={[8, 12, 16]}
              borderRadius="lg"
              overflow="hidden"
              position="relative"
            >
              <Box position="absolute" bg="blue.500" opacity={0.1} inset="0" />
              <Box
                fontSize={['2xl', '3xl', '4xl']}
                fontWeight="medium"
                lineHeight={1.2}
                mb="8"
              >
                Magnet PRO
                <br />
                <Box as="span" color="blue.300">
                  $20 / month
                </Box>
              </Box>
              <Flex flex="1" flexDir="column" gap="3" fontSize="18px">
                <Bullet isChecked label="Unlimited GPT-4 assistant usage" />
                <Bullet
                  isChecked
                  icon="/integrations/linear.png"
                  label="Linear issues integration"
                />
                <Bullet
                  isChecked
                  icon="/integrations/github.png"
                  label="GitHub issues integration"
                />
                <Bullet
                  isChecked
                  icon="/integrations/notion.png"
                  label="Notion docs integration"
                />
              </Flex>
            </Box>
          </Flex>
        </Section>
      </Flex>
      <Box
        bg="blackAlpha.500"
        py={48}
        w="full"
        as="footer"
        color="gray.300"
        className={degular.className}
        px={['16px', '32px', '64px', '80px']}
      >
        <Section mb={0}>
          <Box mb={4}>
            <Logo width={50} />
          </Box>

          <SectionHeading
            title="Try Magnet for free"
            description="Includes 1-day PRO plan trial — no credit card required"
          />

          <Box>
            <Button
              as="a"
              px={6}
              py={2}
              h="auto"
              flex="none"
              bgColor="brandBlue.500"
              color="gray.900"
              _hover={{ bgColor: 'gray.500' }}
              borderRadius="lg"
              href="/code"
              fontSize="3xl"
              fontWeight="semibold"
            >
              Get started
            </Button>
          </Box>

          <Text color="gray.500" mt={48} fontSize="sm">
            &copy; 2023 Toolkit AI, Inc.
          </Text>
        </Section>
      </Box>
    </>
  );
};

const PromptTemplate = ({ children, ...rest }: BoxProps) => (
  <Box
    rounded="lg"
    border="1px solid"
    minW={40}
    color="gray.400"
    h={40}
    p={4}
    flex={1}
    {...rest}
  >
    <Text fontWeight="medium" fontSize={['lg', 'xl', '2xl']} lineHeight={1.2}>
      {children}
    </Text>
  </Box>
);

const Section = (props: FlexProps) => (
  <Flex
    as="section"
    mx="auto"
    flexWrap="wrap"
    flexDir="column"
    mb={48}
    width="full"
    maxW="1200px"
    {...props}
  />
);

const SectionHeading = ({
  title,
  description,
}: {
  title: React.ReactNode;
  description: string;
}) => (
  <>
    <Text as="h2" fontWeight="semibold" mb={2} fontSize={['4xl', '5xl', '6xl']}>
      {title}
    </Text>
    <Text fontSize="2xl" mb={[12, 20]}>
      {description}
    </Text>
  </>
);

const Key = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    as="span"
    color="gray.400"
    fontSize={['3xl', '3xl', '4xl']}
    rounded="lg"
    border="1px solid"
    borderBottomWidth="3px"
    borderColor="gray.600"
    letterSpacing="6px"
    pl={4}
    pr={2}
    py={0.5}
    fontFamily="ui-monospace, Menlo, Monaco, monospace"
  >
    {children}
  </Box>
);

const Feature = ({
  title,
  description,
  imageUrl,
}: {
  title: string;
  description: string;
  imageUrl: string;
}) => (
  <Flex flex="1" flexDir="column">
    <Flex
      borderRadius="xl"
      border="1px solid"
      borderColor="gray.800"
      bg="blackAlpha.300"
      p={[4, 8, 12]}
      minH={300}
      maxW={580}
      alignItems="center"
      justifyContent="center"
    >
      <Image alt={title} w="full" src={imageUrl} />
    </Flex>
    <Text
      as="h3"
      lineHeight={1.2}
      my="3"
      flex="none"
      fontSize={['3xl', '4xl']}
      fontWeight="medium"
      color="gray.300"
    >
      {title}
    </Text>
    <Text
      mb="8"
      color="gray.300"
      flex="1 1 auto"
      fontSize={['lg', 'xl', '2xl']}
    >
      {description}
    </Text>
  </Flex>
);

const Tab = ({
  color,
  children,
  title,
  description,
}: React.PropsWithChildren<{
  color: string;
  title: string;
  description: string;
}>) => {
  return (
    <Flex
      flexDir="column"
      gap={2}
      fontSize="xl"
      alignItems="center"
      flex={1}
      color={color}
    >
      {children}
      <Text
        fontWeight="semibold"
        lineHeight={1.1}
        textAlign="center"
        fontSize={['2xl', '2xl', '3xl']}
      >
        {title}
      </Text>
      <Text
        color="gray.300"
        lineHeight={1.4}
        fontSize={['md', 'lg', 'xl']}
        textAlign="center"
      >
        {description}
      </Text>
    </Flex>
  );
};

const Bullet = ({
  label,
  icon,
  isChecked,
}: {
  label: string;
  icon?: string;
  isChecked?: boolean;
}) => (
  <Flex
    gap={1}
    alignItems="center"
    fontSize={['md', 'lg', 'xl']}
    color={isChecked ? 'blue.500' : 'brandRed.500'}
  >
    <Box as="span" flex="none" mr={1}>
      {isChecked ? <CheckCircle size="18px" /> : <XCircle size="18px" />}
    </Box>
    {icon && <Image w="24px" h="24px" src={icon} alt={label} />}
    <Box color="whiteAlpha.800" as="span">
      {label}
    </Box>
  </Flex>
);

export default Home;
